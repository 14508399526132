<script setup lang="ts">
import { shallowRef } from 'vue';

const appVersion = import.meta.env.APP_VERSION;
const appName = import.meta.env.APP_NAME;

const footerLink = shallowRef([
  {
    title: 'Help'
  },
  {
    title: 'Terms'
  }
]);
</script>
<template>
  <v-footer class=" footer noselect">
    <v-row justify="center" no-gutters>

      <v-col class="text-right">
        <a v-for="(item, i) in footerLink" :key="i" class="mx-2 text-caption text-darkText" href="/">
          {{ item.title }}
        </a>
      </v-col>

      <v-col class="text-caption text-right">{{ appName}} v.{{ appVersion }}</v-col>
    </v-row>
  </v-footer>
</template>
