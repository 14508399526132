<template>
  <slot />
</template>

<script lang="ts" setup>

</script>

<script lang="ts">
import { lifecycleLoggerMixin } from '../mixins/lifecycleLoggerMixin';

export default {
  name: "app-layout-fullpage",
  //mixins: [lifecycleLoggerMixin],
}
</script>
