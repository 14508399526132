import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

interface ClientSessionState {
  sessionId: string;
}

// client session store - used in websockets
export const useClientSessionStore = defineStore('client-session', {
  state: (): ClientSessionState => ({ sessionId: '' }),
  getters: {
    // call this from components
    getSessionId: (state) => {
      if (!state.sessionId) {
        state.sessionId = uuidv4(); // Genera un nuovo UUID se non esiste
      }
      return state.sessionId;
    }
  },
  persist: {
    key: 'client-session',
    storage: sessionStorage,
  },
});

export default useClientSessionStore;
