import { createNotivue, NotivueConfig } from 'notivue'

import 'notivue/animations.css' // Only needed if using default animations
import 'notivue/notification.css'

// https://docs.notivue.smastrom.io/stream-customization/configuration#default-configuration-and-notification-options
const config: NotivueConfig = {
  pauseOnHover: true,
  pauseOnTouch: true,
  pauseOnTabChange: true,
  enqueue: true,
  avoidDuplicates: true,
  position: 'top-right', // 'top-left', 'top-right', 'bottom-left', 'bottom-center', 'bottom-right'
  teleportTo: 'body', // Or HTMLElement or `false` to disable
  limit: 3,
  animations: {
    enter: 'Notivue__enter',
    leave: 'Notivue__leave',
    clearAll: 'Notivue__clearAll'
  },
  transition: 'transform 0.35s cubic-bezier(0.5, 1, 0.25, 1)',
  notifications: {
    global: {
      duration: 9000,
    },
    success: {
      duration: 4000
    },
    error: {
       duration: 10000
    }
  }
}

const notivue = createNotivue(config)
export default (notivue);
