<template>
  <div>
    <!-- all pages are childs of index (causa del primo sistema di layout artigianale, puoi rimuovere la index e rimuovere i child dalla root)-->
    <router-view />
  </div>
</template>

<script lang='ts'>

export default {

}

</script>
