<template>
  <v-card min-width="250" max-width="450" rounded='xl' class="mx-auto pa-5" elevation="2">

    <v-card-title class='text-center text-h4 font-weight-bold' aria-label="Login Form Title" role="heading">
      LOGIN
    </v-card-title>

    <v-form v-model="formValid" @submit.prevent="login" :disabled='$api.isLoading' aria-live="polite" role="form">
      <v-card-item>
        <!-- USERNAME/EMAIL -->
        <div class="text-subtitle-1" aria-label="Account Label" role="label">Account</div>
        <v-text-field v-model="username" color="primary" name="username" type="email" :rules="[rules.required]"
                      :readonly='$api.isLoading' clearable autocomplete='username' class="mb-2"
                      placeholder='Indirizzo email' prepend-inner-icon="$at"
                      aria-label="Email Input" aria-required="true" role="textbox">
        </v-text-field>

        <!-- PASSWORD -->
        <div class="text-subtitle-1 d-flex align-center justify-space-between text-right" aria-label="Password Label"
             role="table">
          Password
          <router-link :to="{ name: 'forgot-password' }" class="text-caption text-decoration-none text-blue"
                       rel="noopener noreferrer" aria-label="Forgot Password Link" role="link">
            Password dimenticata?
          </router-link>
        </div>

        <v-text-field v-model="password" color="primary" name="password" :type="visible ? 'text' : 'password'"
                      :rules="[rules.required]" :readonly='$api.isLoading' autocomplete='current-password' class="mb-2"
                      placeholder='Inserisci la password' prepend-inner-icon='$lock'
                      aria-label="Password Input" aria-required="true" role="textbox">
          <template #append-inner>
            <v-icon v-show='password' :icon="visible ? '$eye-off' : '$eye'" @click="visible = !visible"
                    aria-label="Toggle Password Visibility" role="button"></v-icon>
          </template>
        </v-text-field>

        <div v-if="lastError" class="text-red m-4 text-center">{{ lastError }}</div>

        <v-btn type="submit" color="primary" size="large" rounded :loading='$api.isLoading && formValid'
               :disabled='!formValid' block aria-label="Sign In Button" role="button">Sign In
        </v-btn>

        <div class="mt-4 text-right">
          <router-link :to="{ name: 'signup' }" class="text-caption text-decoration-none text-blue"
                       rel="noopener noreferrer" aria-label="Sign Up Link" role="link">Non sei registrato? Clicca qui
          </router-link>
        </div>
      </v-card-item>

    </v-form>

    <!-- <pre>{{ identityStore.user }}</pre>
    <pre>{{ clientSessionStore.getSessionId }}</pre>-->
  </v-card>

</template>

<script setup lang='ts'>

import { ref } from 'vue';
import { Api } from '@/_api';

//import { useClientSessionStore } from '@/store/clientSessionStore';
//const clientSessionStore = useClientSessionStore();


// https://spike-nuxtjs-free.netlify.app/auth/login

const rules = {
  required: value => !!value || 'Campo obbligatorio',
}

const visible = ref(false);
const formValid = ref(false);
</script>

<script lang='ts'>
import { useUserStore } from '@/store/identityStore';
import { push } from "notivue";

export default {
  name: "user-singin",
  data() {
    return {
      username: '',
      password: '',
      lastError: null,
    }
  },
  methods: {
    async login() {
      const vm = this;
      this.lastError = null;

      const identityStore = useUserStore();

      let dto = new Api.LoginRequestDto({
        email: this.username,
        password: this.password,
        rememberMe: true
      });

      try {
        const response = await this.$api.IdentityClient.authenticatePOST(dto);
        if (!response.user) throw new Error('Risposta non formattata correttamente');

        identityStore.setUser(response.user);
        await vm.$router.replace({ name: response.redirect?.name });

      } catch (e: any) {
        console.error(e)
        this.lastError = e.description || 'Errore durante il login';
      }
    },
  },
  created() {
  }
}
</script>
