// Layout/Interface user's preferences

import { defineStore } from 'pinia';

// TODO: IDEA: load/save from backend

export const useCustomizerStore = defineStore({
  id: 'customizer',
  state: () => ({
    sidebarDrawer: true,
    miniSidebar: false,
    currentTheme: 'DefaultTheme',
  }),

  getters: {},
  actions: {
    toggleSidebar() {
      this.sidebarDrawer = !this.sidebarDrawer;
    },
    minimizeSidebar(payload: boolean) {
      this.miniSidebar = payload;
    },
    setTheme(payload: string) {
      this.currentTheme = payload;
    },
  },
  persist: {
    key: 'customizer',
    storage: localStorage,
  }
});


export type ConfigProps = {
  sidebarDrawer: boolean;
  miniSidebar: boolean;
  currentTheme: string;
};
