<script setup lang="ts">
import { ref } from 'vue';
import UiParentCard from "@/components/UiParentCard.vue";
//import BaseBreadcrumb from '@/components/shared/BaseBreadcrumb.vue';
//import UiParentCard from '@/components/shared/UiParentCard.vue';

// component content
const page = ref({ title: 'Color' });
const breadcrumbs = ref([
  {
    title: 'Color',
    disabled: true,
    href: '#'
  }
]);

const colors = ref([
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
  'surface',
  'on-surface',
  'on-surface-variant',
  'background',
  'on-background',
  'border',
  'on-primary',
  'on-secondary',
  'on-error',
  'facebook',
  'twitter',
  'linkedin',
  'gray100',
  'primary200',
  'secondary200',
]);
</script>

<template>
  <div :title="page.title" :breadcrumbs="breadcrumbs"></div>
  <h2>Configurazione Tema</h2>


  <v-row>
    <v-col cols="12" md="12">
      <UiParentCard title="Weights">
        <v-row>
          <v-col>
            <div style="font-weight: 100;">Peso 100</div>
            <div style="font-weight: 400;">Peso 400</div>
            <div style="font-weight: 500;">Peso 500</div>
            <div style="font-weight: 800;">Peso 800</div>
            <div style="font-weight: 900;">Peso 900</div>
          </v-col>
        </v-row>
      </UiParentCard>
    </v-col>
  </v-row>


  <v-row>
    <v-col cols="12" md="12">
      <UiParentCard title="Color Palette">
        <v-row>
          <v-col md="3" cols="12" v-for="(color, index) in colors" :key="index">
            <v-sheet rounded="md" class="align-center justify-center d-flex" height="100" width="100%" :color="color"
            >class: {{ color }}
            </v-sheet>
          </v-col>
        </v-row>
      </UiParentCard>
    </v-col>
  </v-row>
</template>
