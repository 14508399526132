<template>
  <v-card min-width="200" max-width="450" rounded='xl' class="mx-auto pa-5" elevation="2">

    <v-card-title class='text-center text-h4 font-weight-bold' aria-label="Login Form Title" role="heading">
      Nuovo Account
    </v-card-title>

    <v-form v-model="formValid" @submit.prevent="onSubmit" :disabled='$api.isLoading'>
      <v-card-item>
        <v-row>
          <v-col>
            <!-- NOME -->
            <v-text-field v-model="user.firstName" color="primary" name="firstName" type="email"
              :rules="[rules.required]" :readonly='$api.isLoading' class="mb-2" placeholder='Nome' variant='outlined'
              bg-color='white'>
            </v-text-field>
          </v-col>

          <v-col>
            <!-- COGNOME -->
            <v-text-field v-model="user.lastName" color="primary" name="lastName" type="text" :rules="[rules.required]"
              :readonly='$api.isLoading' class="mb-2" placeholder='Cognome' variant='outlined' bg-color='white'>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- PHONE
        <v-text-field v-model="user.mobilePhone" color="primary" name="phone" type="text" :rules="[rules.required]"
          :readonly='$api.isLoading' autocomplete='phone' class="mb-2" placeholder='Cellulare' variant='outlined'
          bg-color='white' prepend-inner-icon="$phone">
        </v-text-field>-->

        <!-- USERNAME/EMAIL -->
        <v-text-field v-model="user.username" color="primary" name="username" type="email" :rules="[rules.required]"
          :readonly='$api.isLoading' clearable autocomplete='username' class="mb-2" placeholder='Indirizzo email'
          variant='outlined' bg-color='white' prepend-inner-icon="$at">
        </v-text-field>

        <!-- PASSWORD -->
        <v-text-field v-model="user.password" color="primary" name="password" :type="visible ? 'text' : 'password'"
          :rules="[rules.required]" :readonly='$api.isLoading' autocomplete='new-password' class="mb-2"
          :append-inner-icon="visible ? '$eye-off' : '$eye'" @click:append-inner="visible = !visible"
          placeholder='Crea una password' variant='outlined' bg-color='white' prepend-inner-icon='$lock'>
        </v-text-field>

        <!-- PASSWORD CONFIRMATION -->
        <v-text-field v-model="user.passwordConfirm" color="primary" name="passwordConfirm"
          :type="visibleConfirm ? 'text' : 'password'" :rules="[rules.required, rules.passwordMatch]"
          :readonly='$api.isLoading' autocomplete='new-password' class="mb-2"
          :append-inner-icon="visibleConfirm ? '$eye-off' : '$eye'"
          @click:append-inner="visibleConfirm = !visibleConfirm" placeholder='Conferma la password' variant='outlined'
          bg-color='white' prepend-inner-icon='$lock'>
        </v-text-field>

        <v-btn type="submit" color="primary" size="large" class="mt-4" :loading='$api.isLoading && formValid'
          :disabled='!formValid' block>Registrami</v-btn>

        <div class="mt-4 text-right">
          <router-link :to="{ name: 'login' }" class="text-caption text-decoration-none text-blue"
            rel="noopener noreferrer">
            Hai già un account? Accedi qui</router-link>
        </div>
      </v-card-item>
    </v-form>
  </v-card>

</template>


<script setup lang='ts'>
import { ref, reactive } from 'vue';
import { Api } from '@/_api';
import { useUserStore } from '@/store/identityStore';
const identityStore = useUserStore();

const formValid = ref(false);

const visible = ref(false);
const visibleConfirm = ref(false);

const user = reactive({
  username: '',
  password: '',
  passwordConfirm: '',
  firstName: '',
  lastName: '',
  mobile: '',
})

const rules = {
  required: value => !!value || 'Campo obbligatorio',
  passwordMatch: () => user.password === user.passwordConfirm || 'Le password non corrispondono',
}
</script>


<script lang='ts'>

export default {
  name: "user-signup",
  data() {
    return {
    }
  },
  methods: {
    onSubmit() {
      var dto = Api.LoginRequestDto.fromJS(user)!;

      this.$api.IdentityClient.authenticatePOST(dto).then((response) => {
        console.log('registration ok', response.user)
        //identityStore.setUser(response.user);
        //vm.$router.push({ name: 'users' });
      })
    },
  },
  created() {
  }
}
</script>
