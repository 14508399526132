import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { loading } from './loading';
import { push } from 'notivue';

// https://console.anthropic.com/workbench/a2f96861-2dbc-4581-b75f-0fa24712800e

// ! START THE REQUEST
const onRequest = (config: AxiosRequestConfig): any => {
  //console.info(`[request >]`, config);

  loading.start();
  // TODO: finire di implementare il timer // ma che cazzo me ne frega del time?
  //config.meta = config.meta || {};
  //config.meta.requestStartedAt = new Date().getTime();

  return config;
}

// ! REQUEST ERROR
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error]`, error);
  loading.done();

  //if (error && error.meta.toastId !== undefined) {
  //  toast.dismiss(error.meta.toastId);
  //}

  const message = parseError(error); // QUESTO NON VABENE EH
  //toast(message, { hideProgressBar: false, closeOnClick: false, draggable: false, timeout: 6660, closeButton: 'button', type: TYPE.ERROR });
  ////////// push.error(message);

  return Promise.reject(error);
}


// ! RESPONSE OK
const onResponse = (response: AxiosResponse): any => {
  console.info(`[response <]`, response, response.status);
  loading.done();

  return response;
}


// ! RESPONSE ERROR
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error]`, error);
  loading.done();

  const message = parseError(error);

  if (typeof message === 'string') {
    //toast(message, { hideProgressBar: false, closeOnClick: false, draggable: false, timeout: 6660, closeButton: 'button', type: TYPE.ERROR });
    push.error(message);

  } else if (Array.isArray(message)) {
    for (let i = 0; i < Math.min(message.length, 3); i++) {
      push.error(message[i]);

      //toast(message[i], { hideProgressBar: false, closeOnClick: false, draggable: false, timeout: 6660, closeButton: 'button', type: TYPE.ERROR });
    }
  }

  return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

// TODO: gestire errori: 1. errori di validazione 2. errori di server generici 3. errori di rete
// TODO: preparare endpoint di test per ogni tipo di errore
function getDescription(data: any): string[] {
  if (Array.isArray(data)) {
    return data.filter(item => item && item.description).map(item => item.description);
  } else if (data && typeof data.description === 'string') {
    return [data.description];
  }

  console.warn('missing data value', data);
  return [];
}

const parseError = (error: Error | AxiosError): string | string[] => {
  if (axios.isAxiosError(error)) {

    if (error.code === 'ERR_BAD_REQUEST' && error.response?.data) {
      const data = error.response?.data;
      console.warn('ERR_BAD_REQUEST', data, error)

      return getDescription(data);
    } else if (error.response == undefined && error.status == undefined) {
      return `Server non raggiungibile: ${error.message}`
    } else {
      if (error.response) { // The request was made and the server responded with a status code
        if (error.response.data.title) {
          return `${error.response.data.title}`
        } else {
          return error.response.statusText;
        }
      }
    }

    // Access to config, request, and response
    // TODO handle http error
    // TODO: handle aspnet core error
  } else if (error instanceof Error) { // Come lo simulo?
    return error.message
  }

  return "Errore sconosciuto";
};

//interface ValidationErrorResponse {
//  errors: {
//    [key: string]: string[];
//  };
//}
