import { Api } from '@/_api';

import { defineStore } from 'pinia'

import { getApi } from '@/plugins/httpService';

interface UserState {
  user: Api.ApplicationUserDto;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: new Api.ApplicationUserDto({
      id: '',
      firstName: 'Ospite',
      lastName: '',
      displayName: '',
      email: '',
      roles: []
    })
  }),
  getters: {
    displayName: (state) => {
      return state.user.displayName || `${state.user.firstName} ${state.user.lastName}`.trim() || `${state.user.id}`;
    },
    getInitials: (state) => {
      const { firstName, lastName } = state.user;
      return `${firstName?.charAt(0)}${lastName?.charAt(0)}`.trim().toUpperCase();
    }
  },
  actions: {
    setUser(userData: Api.ApplicationUserDto) {
      this.user = userData;
    },
    async logout() {
      try {
        const api = getApi();
        await api.IdentityClient.logout();

      } finally {
        this.$reset();
        this.$persist();

        location.reload();
      }
    }
  },
  persist: {
    key: 'user',
    storage: localStorage,
  },
})

export default useUserStore;
