<template>
  <v-container>

    <div class="d-flex align-center" style="height: 80vh">

      <v-row align="center" justify="center">

        <v-col cols=12 md="5" class='justify-center pt-10 pb-10'>
          <h1 class='text-center text-h1 font-weight-black'>FAKE CRM</h1>
        </v-col>


        <v-col>
          <router-view></router-view>
        </v-col>

      </v-row>

    </div>

  </v-container>
</template>

<script setup lang='ts'>

</script>

<script lang='ts'>

export default {
  name: "unauthenticated-view",
  data() {
    return {}
  },
  methods: {},
  created() {
  }
}
</script>
