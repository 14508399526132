<template>
  <v-container>
    <p>Here will start a web request</p>

    <v-skeleton-loader type="paragraph" v-if='!response'></v-skeleton-loader>
    <pre v-else>{{ response }}</pre>


    <v-btn v-on:click='noHost'>
      Host non raggiungibile
    </v-btn>

    {{ $api.isLoading }}
    <v-btn v-on:click='withException' :loading='$api.isLoading'>
      Exception
    </v-btn>

    <v-form v-model="valid" @submit.prevent="submitError" :disabled='$api.isLoading'>
      <v-container>
        <v-row>
          <v-col cols="12">
            <VTextField v-model="formData.name" :rules="nameRules" :counter="10" label="Name" required
              placeholder='Nome'>
            </VTextField>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="formData.phone" placeholder='Server-side rules' hide-details required></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="formData.email" :rules="emailRules" label="E-mail" hide-details
              required></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-switch label="Switch" inset></v-switch>
          <v-col>
            <v-btn type="submit" :loading='$api.isLoading' :disabled='!valid'>
              Validation Error (submit)
            </v-btn>

          </v-col>

          <v-col></v-col>
          <pre>is valid: {{ valid }}</pre>
        </v-row>
        <pre>{{ formData }}</pre>

      </v-container>
    </v-form>

  </v-container>
</template>


<script lang='ts'>
import { Api } from '@/_api';

import { TYPE, useToast } from 'vue-toastification';
const toast = useToast();

import { useClientSessionStore } from '@/store/clientSessionStore';


export default {
  name: "web-request",
  data() {
    return {
      response: null,
      valid: false,
      formData: Api.FormDataTest.fromJS({
        name: 'fabio',
        phone: '340',
        email: 'aaa@bbb.ccc',
      })!,
      emailRules: [
        (value: string) => {
          if (value) return true

          return 'E-mail is requred.'
        },
        (value: string) => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
      nameRules: [
        (value: string) => {
          if (value) return true

          return 'Name is required.'
        },
        (value: string) => {
          if (value?.length <= 10) return true

          return 'Name must be less than 10 characters.'
        },
      ],
    };
  },
  methods: {
    withException() {
      this.$api.ErrorClient.throwException().then(response => {
        console.warn(response);
      })
        .catch(error => {
          console.warn(error);
        });
    },
    noHost() {
      this.$http.get('http://localhost:0000/api/').then(response => {
      })
        .catch(error => { });
    },
    submitError(e) {
      //console.log(e, this.valid)
      if (!this.valid) return;

      this.$api.ErrorClient.currentRequest(this.formData).then(response => {
        //console.info(response);
      })
        .catch(error => {
          //console.warn(error);
        });
    },
  },
  mounted() {
    const clientSessionStore = useClientSessionStore();
    //this.formData.name = clientSessionStore.sessionId;
    console.log('mounted', clientSessionStore.sessionId)
  },
};
</script>
