import { ThemeDefinition } from "vuetify";

// TODO: impostare il testo a #2a3547
//const f2LightTheme: ThemeDefinition = {
//  dark: false,
//  colors: {
//    'on-surface': '#252032',
//    background: '#e4ecf9',
//    //surface: '#FFFFFF',
//    primary: '#0a8adc',
//    //'primary-darken-1': '#3700B3',
//    //secondary: '#03DAC6',
//    //'secondary-darken-1': '#018786',
//    //error: '#B00020',
//    //info: '#2196F3',
//    //success: '#4CAF50',
//    //warning: '#FB8C00',
//  },
//}

// https://vuetifyjs.com/en/features/theme/#javascript

const DefaultTheme: ThemeDefinition = {
  dark: false,

  colors: {
    primary: '#1677ff',
    secondary: '#03c1da',
    info: '#1ab2d5',
    success: '#4CAF50',
    warning: '#FFC107',
    error: '#F44336',
    surface: '#ffffff',
    'on-surface': '#212121', // Determines the text and icon color displayed on the surface.
    'on-surface-variant': '#8c8c8c',
    background: '#fafafb',
    'on-background': '#212121', // Determines the text and icon color displayed on the background.
    border: '#e6ebf1',
    'on-primary': '#ffffff',
    'on-secondary': '#ffffff',
    'on-error': '#ffffff',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#f5f5f5',
    primary200: '#a1d2ff',
    secondary200: '#eeeeee',
    'borderLight': '#e6ebf1',
    'border-light': '#e6ebf1',


    accent: '#FFAB91',
    lightprimary: '#e6f4ff',
    lightsecondary: '#f5f5f5',
    lightsuccess: '#EAFCD4',
    lighterror: '#FFE7D3',
    lightwarning: '#FFF6D0',
    darkText: '#212121',
    lightText: '#8c8c8c',
    darkprimary: '#0958d9',
    darksecondary: '#7a7878',
    inputBorder: '#a1a1a5',
    containerBg: '#fafafb',
  },
  variables: {
    'border-color': '#f0f0f0',
    'carousel-control-size': '10px',
    //'card-shadow': '0px 1px 4px rgba(255, 0, 0, 0.08)',
    gradient: 'linear-gradient(250.38deg, #e6f4ff 2.39%, #69b1ff 34.42%, #1677ff 60.95%, #0958d9 84.83%, #002c8c 104.37%)',
    gradient2: 'linear-gradient(to right, rgb(9, 89, 218), rgb(22, 119, 255))',
  },
};

export { DefaultTheme };


/*


interface BaseColors {
    background: string;
    surface: string;
    primary: string;
    secondary: string;
    success: string;
    warning: string;
    error: string;
    info: string;
}
interface OnColors {
    'on-background': string;
    'on-surface': string;
    'on-primary': string;
    'on-secondary': string;
    'on-success': string;
    'on-warning': string;
    'on-error': string;
    'on-info': string;
}

 */
