<template>
  <div>


    <v-row class='justify-center mb-5'>

      <v-col cols='12' sm='6'>

        <h2 class='title mb-4'>Informazioni personali</h2>

        <!-- USERNAME/EMAIL -->
        <v-text-field v-model="user.email" disabled name="username" type="email" :rules="[rules.required]"
          :readonly='$api.isLoading' clearable autocomplete='username' placeholder='Indirizzo email'
          prepend-inner-icon="$at">
        </v-text-field>

        <v-form>
          <!-- NOME -->
          <v-text-field v-model="user.firstName" name="firstName" type="email" :rules="[rules.required]"
            :readonly='$api.isLoading' placeholder='Nome'>
          </v-text-field>
          <!-- COGNOME -->
          <v-text-field v-model="user.lastName" name="lastName" type="text" :rules="[rules.required]"
            :readonly='$api.isLoading' placeholder='Cognome'>
          </v-text-field>
          <!-- PHONE -->
          <v-text-field v-model="user.mobilePhone" name="phone" type="text" :rules="[rules.required]"
            :readonly='$api.isLoading' autocomplete='phone' placeholder='Cellulare' prepend-inner-icon="$phone">
          </v-text-field>

          <v-btn type="submit" color="primary" size="large" :loading='$api.isLoading && formValid'>Salva
            modifiche</v-btn>
        </v-form>
      </v-col>


      <!-- COLONNA INDIRIZZO-->
      <v-col cols='12' sm='6'>

        <h2 class='title mb-4'>Indirizzo</h2>
        <v-form :disabled='$api.isLoading'>

          <!--
          <v-text-field v-model="user.addressLine1" name="address" type="text" :rules="[rules.required]"
            :readonly='$api.isLoading' placeholder='Via/Piazza Indirizzo' autocomplete='billing address-line1'>
          </v-text-field>
          -->

          <!--
          <v-row>
            <v-col>
              <v-text-field v-model="user.city" name="city" type="text" :rules="[rules.required]"
                :readonly='$api.isLoading' placeholder='Città' autocomplete='billing address-level2'>
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field v-model="user.region" name="region" type="text" :rules="[rules.required]"
                :readonly='$api.isLoading' placeholder='Provincia' autocomplete='billing address-level1'>
              </v-text-field>
            </v-col>
          </v-row>
-->
        </v-form>
      </v-col>


    </v-row>

    <!-- COLONNA CAMBIO PASSWORD-->
    <v-row class='justify-center mb-5'>


      <v-col cols='12' sm='6'>

        <!-- INFORMAZIONI DI PAGAMENTO -->
        <v-col cols='12' sm='6'>
          <h2 class='title mb-4'>Metodo di Pagamento</h2>
          <!-- STRIPE FORM -->
          ...
        </v-col>

      </v-col>


      <v-col cols='12' sm='6'>
        <!--<PasswordReset></PasswordReset>-->
      </v-col>


    </v-row>

    <v-row class='justify-center mb-5'>
      <v-col cols='12' sm='6'></v-col>
      <v-col cols='12' sm='6'></v-col>
    </v-row>

  </div>
</template>


<script setup lang='ts'>
import { ref, reactive } from 'vue';
import { Api } from '@/_api';
import { useUserStore } from '@/store/identityStore';
const identityStore = useUserStore();

const formValid = ref(false);

const visible = ref(false);
const visibleConfirm = ref(false);

const user = reactive({
  email: '',
  password: '',
  passwordConfirm: '',
  firstName: '',
  lastName: '',
  mobilePhone: '',
})


user.firstName = identityStore.user.firstName || '';
user.lastName = identityStore.user.lastName || '';
user.email = identityStore.user.email || '';


const rules = {
  required: value => !!value || 'Campo obbligatorio',
  passwordMatch: () => user.password === user.passwordConfirm || 'Le password non corrispondono',
}
</script>


<script lang='ts'>
//import PasswordReset from '@/components/User/Profile/PasswordReset'

export default {
  name: "users-profile-view",
  //components: { PasswordReset },
  data() {
    return {
      panel_password_reset: false,
      loading: false,
      form: {
        firstName: 'John',
        lastName: 'Doe',
        contactEmail: 'john@doe.com',
        avatar: 'MALE_CAUCASIAN_BLOND_BEARD'
      },
      showAvatarPicker: false
    }
  },
  methods: {
    submit() {
      // panel_password_reset === 0 aggiungi password

      //var dto = Api.LoginRequestDto.fromJS({
      //  email: this.username,
      //  password: this.password,
      //  rememberMe: true
      //})!;
      //var dtox = new Api.LoginRequestDto({
      //  email: this.username,
      //  password: this.password,
      //  rememberMe: true
      //});

    },

  }
};
</script>
