<template>
  <VerticalSidebar></VerticalSidebar>

  <MainAppBar>

    <v-container class="mx-auto d-flex align-center justify-center">
      <v-btn v-for=" link in links" :key="link" :text="link" variant="text"></v-btn>
      <v-spacer></v-spacer>

      <ProfileBadge>
      </ProfileBadge>
    </v-container>

  </MainAppBar>

  <!-- MAIN CONTAINER -->
  <v-main class="page-wrapper">

    <v-container fluid>
      <slot/>
    </v-container>

    <!-- FOOTER -->
    <v-container fluid class="pt-0">
      <div>
        <FooterPanel/>
      </div>
    </v-container>
  </v-main>

</template>

<script lang="ts" setup>

import FooterPanel from "@/layouts/default/footer/FooterPanel.vue";
import VerticalSidebar from "@/layouts/default/vertical-sidebar/VerticalSidebar.vue";
</script>

<script lang="ts">

import { useDisplay } from 'vuetify'

import MainAppBar from '@/components/MainAppBar.vue';
import ProfileBadge from '@/components/ProfileBadge.vue';


export default {
  name: "app-layout-default",
  //mixins: [lifecycleLoggerMixin],

  components: {
    MainAppBar,
    ProfileBadge,
  },
  data() {
    return {
      elevation: 0,
      links: [
        'Dashboard',
        'Profile',
      ],
    };
  },
  methods: {},
  created() {
    const { width, mobile } = useDisplay()
  },
}
</script>


<style scoped>
.translucent {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(11px) grayscale(30%);
}

.Notivue__notification {
  --nv-gap: 1rem;
  --nv-z: 2000;
}
</style>
