<template>

  <v-navigation-drawer
    v-model="customizer.sidebarDrawer"
    elevation="0"
    rail-width="60"
    mobile-breakpoint="lg"
    expand-on-hover
    :rail="customizer.miniSidebar"

  >
    <div class="pa-5">
      <Logo/>
    </div>

    <v-list density="compact" nav>
      <v-list-item prepend-icon="$view-dashboard" title="Dashboard" value="Dashboard"
                   :to="{ name: 'dashboard' }" color="primary"></v-list-item>
      <v-list-item prepend-icon="$homeCity" title="Home" value="home"></v-list-item>

      <v-list-item prepend-icon="$palette" title="Theme" value="theme" :to="{ name: 'theme' }"></v-list-item>

      <v-list-item prepend-icon="$account" title="Account" value="account"></v-list-item>
      <v-list-item prepend-icon="$accountGroupOutline" title="Utenti" value="users"
                   :to="{ name: 'users' }"></v-list-item>
    </v-list>

  </v-navigation-drawer>

</template>

<script setup lang="ts">

import { useCustomizerStore } from '@/store/customizer';

const customizer = useCustomizerStore();


</script>


<script lang="ts">

import { useDisplay } from 'vuetify'
import Logo from "@/components/Logo.vue";

export default {
  name: "app-layout-default-vertical-sidebar",
  components: { Logo },
  data() {
    return {
      drawer: Boolean(null),
      elevation: 0,
      links: [
        'Dashboard',
        'Profile',
      ],
    };
  },
  methods: {
    updateData(newValue) {
      this.drawer = newValue;
    }
  },
  created() {
    const { width, mobile } = useDisplay()
    this.drawer = !mobile.value;
  },
}
</script>


<style scoped>
.v-navigation-drawer {
  border-right: 1px solid #ebeff3;
}

</style>
