/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
//import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'
import '@/styles/settings.scss' // < for custom variables
import '@/scss/style.scss';

//import 'typeface-roboto/index.css';

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify'
//import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { md3 } from 'vuetify/blueprints'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import LuxonAdapter from '@date-io/luxon'

const luxon = new LuxonAdapter({ locale: 'it' });

import {
  mdiAccount,
  mdiAccountGroupOutline,
  mdiChevronLeft, mdiChevronRight,
  mdiHomeCity,
  mdiAt,
  mdiEye,
  mdiEyeOff,
  mdiPhone,
  mdiLock,
  mdiViewDashboard,
  mdiLogout,
  mdiPalette,
  mdiTrendingDown,
  mdiTrendingUp,
} from '@mdi/js'

import { DefaultTheme } from '@/theme/LightTheme';


// https://vuetifyjs.com/en/features/theme/#typescript
// https://vuetifyjs.com/en/styles/colors/#material-colors


// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// https://vuetifyjs.com/en/features/theme/#javascript
// edit efaults https://vuetifyjs.com/en/features/global-configuration/
export default createVuetify({
  components,
  directives,
  ssr: false,
  blueprint: md3,
  theme: {
    cspNonce: 'dQw4w9WgXcQ', // https://vuetifyjs.com/en/features/theme/#csp-nonce
    defaultTheme: 'DefaultTheme',
    themes: {
      DefaultTheme,
    },
  },
  defaults: {
    global: {
      ripple: true,
    },
    VBtn: {
      variant: 'tonal',
      elevation: 0,
    },
    VSwitch: {
      variant: 'inset',
      color: 'primary'
    },
    VTextField: {
      rounded: 'lg',
      variant: 'outlined',
      //variant: 'solo-filled',
      //flat: true,

      class: 'mb-2',
      minWidth: 60,
    },
    VTextarea: {
      variant: 'solo-filled'
    },
    VDataTable: {
      itemsPerPage: 100,
      itemsPerPageOptions: [50, 100, 200]
    },
    VDataTableServer: {
      itemsPerPage: 100,
      itemsPerPageOptions: [50, 100, 200]
    },
    VCard: {
      rounded: 'md'
    },
    VSheet: {},
    VTooltip: {
      location: 'top'
    }
  },
  icons: {
    defaultSet: 'mdi',
    sets: { mdi, },
    aliases: {
      ...aliases,
      account: mdiAccount,
      'chevron-left': mdiChevronLeft,
      'chevron-right': mdiChevronRight,
      homeCity: mdiHomeCity,
      accountGroupOutline: mdiAccountGroupOutline,
      at: mdiAt,
      eye: mdiEye,
      'eye-off': mdiEyeOff,
      phone: mdiPhone,
      lock: mdiLock,
      'view-dashboard': mdiViewDashboard,
      logout: mdiLogout,
      palette: mdiPalette,
      trendingDown: mdiTrendingDown,
      trendingUp: mdiTrendingUp,
    },
  },
  date: {
    adapter: luxon
  }
})
