import { reactive } from 'vue';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({ showSpinner: false });

export const loading = reactive({
  count: 0,
  get isLoading() {
    return this.count !== 0
  },
  start() {
    this.count++;
    if (this.count === 1) {
      NProgress.start();
    }
  },
  done() {
    this.count--;
    if (this.count <= 0) {
      NProgress.done();
      this.count = 0;
    }
  },
});
