<template>
  <v-container>


  </v-container>
</template>


<script lang='ts'>
import { Api } from '@/_api';


export default {
  name: "users-index-view",
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
    this.$api.SessionClient.me().then(response => {
      //this.response = response;
      //console.log(response.session)
    });
  },
};
</script>
