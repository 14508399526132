<template>
  <v-locale-provider>
    <v-app :class="[]">

      <Notivue v-slot="item">
        <Notification :item="item" :theme="pastelTheme"/>
      </Notivue>

      <component :is='layout'>

        <router-view v-slot="{ Component, route }">

          <transition name="scale-slide">
            <div v-if='true'>
              <component :is="Component" :key="route.path"/>
            </div>
          </transition>
        </router-view>

      </component>

      <!--
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'">
          <component :is="Component" />
        </transition>
      </router-view> -->

    </v-app>
  </v-locale-provider>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { Notivue, Notification, push, pastelTheme } from 'notivue'

const layout = inject('app:layout');

let count = 1

async function pushNotification(n) {
  push.info({
    message: 'John Doe started following you: ' + n,
    props: {
      avatarUrl: `https://i.pravatar.cc/50?u=${++count}`,
      profileScore: count,
      isProfileComplete: Math.random() > 0.5
    }
  })
}

//pushNotification(1);

</script>


<script lang='ts'>

import { lifecycleLoggerMixin } from "@/mixins/lifecycleLoggerMixin";

export default {
  name: 'app-entrypoint',
  //mixins: [lifecycleLoggerMixin],
  data() {
    return {}
  },
  methods: {},
  mounted() {
  },
};
</script>
